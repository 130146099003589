<template>
  <div></div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import LocalStorageService from "@/plugins/LocalStorageService";
const localStorageService = LocalStorageService.getService();
import { checkChangeEmpLang } from "@/utils/common";
import SessionStorageService from "@/plugins/SessionStorageService";
import { Trans } from "@/translations";
const sessionStorageService = SessionStorageService.getService();

export default {
  name: "LoginSuccess",

  data() {
    return {
      accountId: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  created() {
    sessionStorageService.setIsApp(false);
    localStorageService.clearToken();
    if (localStorageService.getAccessToken()) {
      this.getData();
    } else {
      const { accessToken, refreshToken } = this.$route.query;
      if (accessToken && refreshToken) {
        // 로그인이 성공한 상태
        localStorageService.setToken({
          access_token: accessToken,
          refresh_token: refreshToken,
        });
        checkChangeEmpLang();
        this.getData();
      } else {
        this.$router.push("/login");
      }
    }
  },
  methods: {
    ...mapActions([
      "getUser",
      "getSelectPoint",
      "getUserVacList",
      "getWorkTime",
      "getBannerDisplay",
      "getWeather",
      "getMenuWork",
      "getMenuOffice",
      "getMenuFavorite",
    ]),
    async getData() {
      try {
        //await this.getUser()
        await this.getUser().then(async () => {
          const { accountId, companyList, companyId, initLogin, lastPwdChanged, accountInfo } =
            this.userInfo;

          const apiUrl = process.env.VUE_APP_IAM_ACCOUNT_URL;
          // TODO 9월 19일 그랜드 오픈 시 적용하여야 함. matthew.cho
          if (initLogin == true) {
            alert("당일 최초 로그인은 인증을 해야합니다.");
            if (accountInfo == true) {
              alert("등록된 아이디와 이메일이 없습니다. 관리자에게 문의하시기 바랍니다.");
              localStorageService.setCasFlag(true);
              localStorageService.clearToken();
              this.$router.push("/login");
              return;
            }
            window.open(
              `${apiUrl}/api/accounts/certifyUser?userId=${accountId}&companyId=${companyId}`,
            );
            localStorageService.setCasFlag(true);
            localStorageService.clearToken();
            this.$router.push("/login");
            return;
          } else if (lastPwdChanged == true) {
            alert("비밀번호를 변경한지 90일이 경과됐습니다. 비밀번호를 변경해주세요.");
            window.open(`${apiUrl}/api/accounts/initpassword`);
            localStorageService.setCasFlag(true);
            localStorageService.clearToken();
            this.$router.push("/login");
            return;
          } else {
            console.log(accountId, companyList, !this.$route.query.changeCompany);
            console.log(this.userInfo);
            console.log(
              "!this.$route.query.ChangeCompany && companyList.length > 1 :>> ",
              !this.$route.query.changeCompany && companyList.length > 1,
            );
            if (!this.$route.query.changeCompany && companyList.length > 1) {
              this.accountId = accountId;
              this.moveCompany();
              return;
            }

            await this.getBannerDisplay();
            await this.getWeather();
            await this.getMenuWork("WORK");
            await this.getMenuOffice("OFFICE");
            await this.getMenuFavorite("FAVORITE");
            //전자결재 API 데이터 추가

            this.moveHome();
          }
        });

        //this.getSelectPoint();
        // this.getUserVacList();
        // this.getWorkTime();
      } catch (e) {
        console.log(e.message);
      }
    },

    checkCompany() {
      this.moveHome();
    },
    moveHome() {
      this.$router.push({ name: "home" });
    },
    moveCompany() {
      this.$router.push({ name: "selectCompany", params: { accountId: this.accountId } });
    },
  },
};
</script>
